<script setup lang="ts">
const navOptions = [
  { title: "Home", path: "/" },
  { title: "Get Involved", path: "/groups" },
  { title: "Donate", path: "/donate" },
  { title: "Events", path: "/events" },
  { title: "News", path: "/news" },
  { title: "About Us", path: "/about" },
  { title: "Contact", path: "/contact" },
];
const govOptions = [
  { title: "Privacy & Confidentiality", path: "/privacy" },
  { title: "Board, CEO and Committee", path: "/board-ceo-committee" },
  { title: "Important Documents", path: "/important-documents" },
];

// -- finalise links to socials and routing
</script>

<template>
  <CommonContainer tag="footer" class="grid grid-cols-1 items-center py-[--y-margin] pb-4 md:grid-cols-3 md:gap-20 xl:gap-20">
    <div class="col-span-1 flex flex-col items-center space-y-5">
      <img width="200px" src="/logo-no-bg.svg" alt="Wildcare Tasmania logo" />
      <p class="center text-center text-xl font-medium text-accentDark md:text-left xl:text-xl">
        Wildcare Tasmania is a community of volunteers and donors caring for Tasmania's wild places, wildlife and cultural heritage.
        Wildcare has almost 60 volunteer groups that members can join.
      </p>
      <div class="flex items-center gap-7 xl:gap-3">
        <CommonButtonNuxtLink
          icon="fa-brands fa-facebook"
          btn-style="white"
          icon-size="xl"
          to="https://www.facebook.com/wildcaretas"
        ></CommonButtonNuxtLink>
        <CommonButtonNuxtLink
          icon="fa-brands fa-instagram"
          btn-style="white"
          icon-size="xl"
          to="https://www.instagram.com/wildcare_tasmania/"
        ></CommonButtonNuxtLink>
        <CommonButtonNuxtLink
          icon="fa-brands fa-linkedin"
          btn-style="white"
          icon-size="xl"
          to="https://au.linkedin.com/company/wildcare-tasmania"
        ></CommonButtonNuxtLink>
      </div>
    </div>
    <div class="col-span-2 grid w-full grid-cols-1 gap-10 rounded-xl bg-primary p-12 px-20 font-script text-white xl:grid-cols-3">
      <div class="col-span-1 flex flex-col items-center xl:items-start">
        <h5 class="mb-3 text-2xl font-bold text-white">Pages</h5>
        <NuxtLink v-for="item in navOptions" :key="item.title" :to="{ path: item.path }">
          <NavLink>{{ item.title }}</NavLink>
        </NuxtLink>
      </div>
      <div class="col-span-1 flex flex-col items-center xl:col-span-2 xl:items-start">
        <h5 class="mb-3 text-2xl font-bold text-white">Governance</h5>
        <NuxtLink v-for="item in govOptions" :key="item.title" :to="{ path: item.path }">
          <NavLink>{{ item.title }}</NavLink>
        </NuxtLink>
      </div>
    </div>
  </CommonContainer>
  <CommonContainer class="pb-4 text-right">
    <p>
      © {{ new Date().getFullYear() }} Wildcare Incorporated. All rights reserved.
      <a href="https://ionata.com.au/"> Web Design by Ionata Digital </a>
    </p>
  </CommonContainer>
</template>
