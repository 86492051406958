<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

const accountNavOptions = [
  { title: "Account", path: "/account", icon: "fa-solid fa-user" },
  {
    title: "Settings",
    path: "/account/settings",
    icon: "fa-solid fa-gear",
  },
  {
    title: "Subscription",
    path: "/account/subscription",
    icon: "fa-solid fa-credit-card",
  },
];

const user = useUser();
</script>

<template>
  <div class="flex items-center gap-5 rounded bg-primary">
    <Menu v-slot="{ open, close }" as="div" class="relative z-50">
      <MenuButton class="h-[52px] rounded bg-primary px-4 py-3">
        <font-awesome-icon :icon="open ? 'fa-solid fa-x' : 'fa-solid fa-user'" size="lg" class="w-5 text-white" />
      </MenuButton>

      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems class="absolute right-0 top-full mt-2 origin-top-right whitespace-nowrap rounded bg-primary">
          <MenuItem v-for="item in accountNavOptions" :key="item.title" v-slot="{ active }">
            <NuxtLink
              :to="{ path: item.path }"
              :class="[active ? 'bg-secondary text-black' : 'text-white', 'group flex items-center gap-4 px-6 py-4 font-script transition']"
            >
              <span @click="close">
                <font-awesome-icon v-if="item.icon" :icon="item.icon"></font-awesome-icon>
                {{ item.title }}
              </span>
            </NuxtLink>
          </MenuItem>
          <MenuItem v-if="user.user" v-slot="{ active }">
            <NuxtLink
              class="cursor-pointer"
              :class="[active ? 'bg-secondary text-black' : 'text-white', 'group flex items-center gap-4 px-6 py-4 font-script transition']"
              @click="useLogout()"
            >
              <font-awesome-icon icon="fa-solid fa-arrow-left-from-bracket" />
              <span class="ml-2">Log Out</span>
            </NuxtLink>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>
